'use strict';
import {KTDrawer} from "../../m/core/index.js";
import jQuery from 'jquery';
import $ from 'jquery';

import {asSocket, currentView} from './client.js';

if (DEBUG) console.log('websocket/app.js');

(function () {

})(jQuery)

/**
 * A NodeList collection of all HTML elements with the tag name 'app_body' within the document.
 * This variable is used to reference and manipulate or query elements identified by the specified tag.
 *
 * Note:
 * - The NodeList is live, meaning updates to the DOM automatically update the collection.
 * - Accessing elements within the NodeList can be performed using standard array indexing.
 * - If no elements are found, the NodeList will be empty.
 */
let body = document.getElementsByTagName("container");

/**
 * An object representing a collection of modules with functionalities
 * to register and retrieve module information.
 *
 * @property {Object|null} data - Stores the module data registered with this object.
 *                                Initially set to null.
 * @function registerModules - Registers a collection of modules by assigning
 *                           the given data to the `data` property.
 * @param {Object} data - The module data to register.
 * @function get - Retrieves the complete module data stored in the `data` property.
 * @returns {Object|null} The registered module data.
 * @function getModules - Alias for the `get` method. Retrieves the complete module
 *                      data stored in the `data` property.
 * @returns {Object|null} The registered module data.
 * @function getModule - Retrieves a specific module from the registered module data
 *                     by its name.
 * @param {string} moduleName - The name of the module to be retrieved.
 * @returns {*} The module associated with the specified name from the `data` property.
 */
let modules = {
    data: null,
    registerModules: function (data) {
        if (DEBUG) console.log('modules.register()');
        if (DEBUG) console.log(data);
        this.data = {};
        Object.keys(data).forEach(key => {
            this.data[key] = new Module().register(data[key]);
        })
        return this;
    },
    get: function () {
        return this.data;
    },
    getModules: function () {
        return this.data;
    },
    getModule: function (key) {
        return key ? this.data[key] : this.data;
    }
}

// **Einzelnes Modul-Objekt**
class Module {
    constructor() {
        this.data = {
            id: null,
            name: null,
            icon: null,
            icon_type: null,
            type: null,
            show: true,
            show_order: 0,
            topbar: false,
            sidebar: false,
            footer: false,
            hidden: false,
            views: {}
        };
    }

    register(data) {
        this
            .set('id', data.id ?? null)
            .set('name', data.name ?? null)
            .set('icon', data.icon ?? null)
            .set('icon_type', data.icon_type ?? null)
            .set('type', data.type ?? 'dropdown')
            .set('show', data.show ?? true)
            .set('topbar', data.topbar ?? false)
            .set('sidebar', data.sidebar ?? false)
            .set('footer', data.footer ?? false)
            .set('hidden', data.hidden ?? false)
            .set('show_order', data.show_order ?? 0)
            .setViews(data.views ?? {});

        return this;
    }

    set(key, value) {
        this.data[key] = value;
        return this;
    }

    get(key) {
        if (key) {
            return this.data[key];
        }
        return this.data;
    }

    setViews(views) {
        if (!views) return;
        this.data.views = {};

        Object.keys(views).forEach(viewName => {
            this.data.views[viewName] = new View().register(views[viewName]);
        });
    }

    getView(viewName) {
        return this.data.views?.[viewName] ?? null;
    }

    getViews() {
        return this.get('views') ?? null;
    }
}

class View {
    constructor() {
        this.data = {
            id: null,
            name: null,
            link: null,
            hidden: false,
            show: true,
            show_order: 0,
            topbar: false,
            sidebar: false,
            footer: false,
            module: null,
            config: null,
            icon: null,
            icon_type: null,
            type: null,
        }
    }

    register(data) {
        this
            .set('id', data.id ?? null)
            .set('name', data.name ?? null)
            .set('link', data.link ?? null)
            .set('hidden', data.hidden ?? false)
            .set('show', data.show ?? true)
            .set('show_order', data.show_order ?? 0)
            .set('topbar', data.topbar ?? false)
            .set('sidebar', data.sidebar ?? false)
            .set('footer', data.footer ?? false)
            .set('icon', data.icon ?? null)
            .set('icon_type', data.icon_type ?? null)

        return this;
    }

    set(key, value) {
        this.data[key] = value;
        return this;
    }

    get(key) {
        if (key) {
            return this.data[key];
        }
        return this.data;
    }
}

let configs = {
    data: null,
    registerConfig: function (data) {
        // if (DEBUG) console.log('configs.register()');
        // if (DEBUG) console.log(data);
        this.data = data;
    },
    get: function (key = null) {
        if (key) {
            return this.data[key];
        }
        return this.getConfig();
    },
    getConfig: function () {
        return this.data;
    },
};
// Konfigurationsobjekt für die Website
const siteConfig = {
    'app': null,
    'modules': null,
    'current': null,
    'currentModule': null,
    'currentDataSet': null,
    'currentDataSetSourceID': null,
    'reload': function () {
        location.assign(location.href);
    },
    'init': function () {
        console.log('siteConfig.init()');
        console.log(asSocket.get());
    },
    'setCurrentModule': function (module = null, url = null, menu = null) {
        if (DEBUG) console.log('siteConfig.setCurrentModule()')
        // if (DEBUG) console.log(url);
        // if (DEBUG) console.log(siteConfig.modules);
        // if (DEBUG) console.log(menu);
        // Aktuelle Moduldaten basierend auf der aktuellen URL ermitteln
        let matchData = [];
        let checkUrl = (url !== null ? url : window.location.pathname);
        // console.log(checkUrl);
        let modules = null;
        this.setCurrentDataSet(null);
        this.setCurrentDataSetSourceID(null);

        if (url !== null) checkUrl = url;
        this.current = checkUrl;
        this.currentModule = module;

        // console.log(siteConfig.modules);

        if (siteConfig.modules) {
            modules = Object.entries(siteConfig.modules).sort((a, b) => a[1]['config']['order'] - b[1]['config']['order']);
            // console.log(modules);

            for (const [name, data] of modules) {
                // console.log(data);
                const moduleNavigation = Object.values(data.views);
                // console.log(moduleNavigation);

                if (moduleNavigation) {
                    let extractedId;
                    const matchingLink = moduleNavigation.find(nav => {
                        let route = nav.config.route;

                        // Erstelle einen RegExp für {id?} (optional) und {id} (Pflicht)
                        if (route.includes("{id?}")) {
                            // Ersetze {id?} durch eine optionale Ziffernfolge und erfasse sie in einer Gruppe
                            route = route.replace("{id?}", "(\\d+)?");
                        } else if (route.includes("{id}")) {
                            // Ersetze {id} durch eine zwingende Ziffernfolge und erfasse sie in einer Gruppe
                            route = route.replace("{id}", "(\\d+)");
                        }

                        // Füge einen optionalen Schrägstrich am Anfang des regulären Ausdrucks hinzu
                        const routeRegExp = new RegExp(`^/?${route}$`);
                        // console.log(`Prüfe Route ${routeRegExp} gegen URL ${checkUrl}`);

                        // Teste die tatsächliche URL gegen den generierten RegExp
                        const match = checkUrl.match(routeRegExp);

                        if (match) {
                            // Wenn die ID vorhanden ist (im optionalen Fall könnte sie nicht vorhanden sein), speichere sie
                            if (match[1]) {
                                extractedId = match[1];  // ID aus der URL extrahieren
                                // console.log(`Extrahierte ID: ${extractedId}`);
                            }
                            return true;  // Rückgabe von true, um die URL als passend zu markieren
                        }

                        return false;  // Rückgabe von false, falls keine Übereinstimmung gefunden wurde
                    });

                    // console.log(matchingLink);
                    if (matchingLink) {
                        if (extractedId !== undefined && extractedId !== null) {
                            this.setCurrentDataSet(extractedId);
                            this.setCurrentDataSetSourceID(extractedId);
                        }
                        matchData = [name, data, matchingLink, extractedId];  // Speichere auch die ID in matchData
                        break;
                    }
                }
            }


            matchData.push(menu);
            // console.log(matchData);
            this.currentModule = module;
            // console.log(this.currentModule.view.config.route);

            // Beispiel: Zustand hinzufügen
            var page = '';
            if (this.currentModule[2] !== undefined) page = this.currentModule[2].title;
            var stateData = {page: page, url: url};
            var pageTitle = siteConfig.app.name;
            if (this.currentModule[2] !== undefined) pageTitle = siteConfig.app.name + ' - ' + this.currentModule[2].title;
            var url = url;
            history.pushState(stateData, pageTitle, location.href);
        } else {
            console.debug('Fehler beim laden von siteConfig.modules', siteConfig.modules);
        }

        return this;
    },
    'getCurrentModule': function () {
        // if (DEBUG) console.log('siteConfig.getCurrentModule()');
        return this.currentModule;
    },
    'getCurrentDataSet': function () {
        if (DEBUG) console.log('siteConfig.getCurrentDataSet()');
        return this.currentDataSet;
    },
    'setCurrentDataSet': function (dataSet) {
        if (DEBUG) console.log('siteConfig.setCurrentDataSet()');
        this.currentDataSet = dataSet;
        return this;
    },
    'getCurrentDataSetSourceID': function () {
        if (DEBUG) console.log('siteConfig.getCurrentDataSetSourceID()');
        return this.currentDataSetSourceID;
    },
    'setCurrentDataSetSourceID': function (dataSetSourceID) {
        if (DEBUG) console.log('siteConfig.setCurrentDataSetSourceID()');
        this.currentDataSetSourceID = dataSetSourceID;
        return this;
    }
}

let moduleNavigation = {
    data: {},
    refresh: function () {
        if (DEBUG) console.log('moduleNavigation.refresh()');
        this.data = modules.getModules();
        this.build();
    },
    build: function () {
        if (DEBUG) console.log('navigation.build()');
        if (window.location.pathname.includes('/backend')) this.buildBackendMenu();
        else this.buildFrontendMenu();
    },
    buildBackendPageMenu: function () {
        if (!window.location.pathname.includes('/backend')) return;
        if (currentView.get('module') === null) return;
        let menu = $('#mega_menu');
        menu.empty();

        let module = modules.getModule(currentView.get('module'));
        if (module.get('topbar') === false) return;

        const sortedData = Object.entries(module.get('views')).sort((a, b) => {
            return a[1].get('show_order') - b[1].get('show_order');
        });

        let index = 0;
        let offsetX = index % 2 === 0 ? -20 + index * 10 : -10 + index * 10;
        let offsetRTL = Math.abs(offsetX);
        let menuType = module.get('type') === 'dropdown' ? 'dropdown' : 'accordion|lg:dropdown';

        let menuItem = $(`
            <div class="menu-item" 
                data-menu-item-offset="${offsetX}px,10px|lg:${offsetX + 10}px,10px" 
                data-menu-item-offset-rtl="${offsetRTL}px,10px|lg:${offsetRTL + 10}px,10px"
                data-menu-item-placement="bottom-start" 
                data-menu-item-placement-rtl="bottom-end"
                data-menu-item-toggle="${menuType}"
                data-menu-item-trigger="click|lg:hover">
                
                <div class="menu-link border-b border-b-transparent menu-item-active:border-b-gray-400 menu-item-here:border-b-gray-400">
                    <span class="menu-title text-2sm text-gray-800 dark:menu-item-here:text-gray-900 dark:menu-item-active:text-gray-900
                        menu-item-show:text-gray-900 menu-item-here:text-gray-900 menu-item-active:font-medium menu-item-here:font-medium">
                        ${module.get('name') || module.get('id')}
                    </span>
                    <span class="menu-arrow flex lg:hidden">
                        <i class="ki-filled ki-plus text-2xs menu-item-show:hidden"></i>
                        <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex"></i>
                    </span>
                </div>

                <div class="menu-dropdown ${menuType === 'dropdown' ? 'menu-default m-0 py-2.5 w-full max-w-[220px]' : 'w-full gap-0 lg:max-w-[875px] hidden'}">
                    ${module.get('type') === 'dropdown' ? this.buildDropdownItems(module.get('views')) : this.buildGridMenu(module.get('views'))}
                </div>
            </div>
        `);

        menuItem.find('.menu-link').on('click', function () {
            menuItem.find('.menu-dropdown').slideToggle(200);
        });

        menu.append(menuItem);

    },
    buildBackendMenu: function () {
        if (DEBUG) console.log('navigation.buildBackendMenu()');
        this.data = modules.getModules();
        const sortedData = Object.entries(this.data).sort(([, a], [, b]) => {
            return a.get('show_order') - b.get('show_order');
        });
        const siteMenu = $('#sidebar_menu');
        siteMenu.empty();
        sortedData.forEach(([moduleName, module]) => {
            if (!module.get('sidebar')) return;
            const moduleId = `${module.get('id')}_collapse`;

            let menuItem = $('<div class="menu-item" data-menu-item-toggle="accordion" data-menu-item-trigger="click"></div>');

            let menuHeader = $(`
                <div class="menu-link flex items-center cursor-pointer border border-transparent gap-[10px] ps-[10px] pe-[10px] py-[6px]" tabindex="0">
                    <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                        <i class="${module.get('icon_type')} ${module.get('icon')} fs-2"></i>
                    </span>
                    <span class="menu-title text-sm font-medium text-gray-800 menu-item-active:text-primary menu-link-hover:!text-primary">
                        ${module.get('name')}
                    </span>
                    <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ms-1 me-[-10px]">
                        <i class="ki-filled ki-plus text-2xs menu-item-show:hidden"></i>
                        <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex"></i>
                    </span>
                </div>
            `);

            let subMenu = $(`
                <div id="${moduleId}" class="menu-accordion gap-0.5 ps-[10px] relative before:absolute before:start-[20px] before:top-0 before:bottom-0 before:border-s before:border-gray-200">
                </div>
            `);

            menuItem.append(menuHeader);
            menuItem.append(subMenu);

            if (module.get('views')) {
                const sortedViews = Object.entries(module.get('views')) // Objekt → Array umwandeln
                    .sort((a, b) => {
                        return (a[1].get('show_order') || 0) - (b[1].get('show_order') || 0); // Fallback-Wert
                    });
                sortedViews.forEach(([viewName, view]) => {
                    if (!view.get('sidebar')) return;
                    if (view !== undefined && view.get('show') === true && view.get('hidden') === false) {
                        let link = view.get('link').replace(/\/\{\w+\?}/g, '');
                        let subMenuItem = $(`
                            <div class="menu-item">
                              <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] ps-[10px] pe-[10px] py-[8px]" data-module="${module.get('id')}" data-path="${view.get('id')}" href="/${link}" tabindex="0">
                               <span class="menu-bullet flex w-[6px] -start-[3px] rtl:start-0 relative before:absolute before:top-0 before:size-[6px] before:rounded-full rtl:before:translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary">
                               </span>
                               <span class="menu-title text-2sm font-normal text-gray-800 menu-item-active:text-primary menu-item-active:font-semibold menu-link-hover:!text-primary">
                                ${view.get('name')}
                               </span>
                              </a>
                             </div>
                            `);
                        subMenu.append(subMenuItem);

                        subMenuItem.find('a').on('click', function (e) {
                            e.preventDefault();
                            if (DEBUG) console.log(`Route for view: ${view.get('link')}`);

                            const sidebarEl = document.querySelector('#sidebar');
                            // Falls dein Theme ein KTDrawer verwendet:
                            const drawer = KTDrawer.getInstance(sidebarEl);
                            if (drawer) {
                                drawer.hide();
                            } else {
                                // Fallback: Klassen manuell anpassen
                                $(sidebarEl).removeClass('open').addClass('hidden');
                            }
                        });
                    }
                });
            }

            if (!$('#sidebar_menu').hasClass('fixed')) {
                $('.menu-accordion').slideUp(200);
            }

            siteMenu.append(menuItem);
        });
    },
    buildFrontendMenu: function () {
        if (DEBUG) console.log('navigation.buildFrontendMenu()');

        this.data = modules.getModules(); // Holt die Module
        const sortedData = Object.entries(this.data).sort((a, b) => a[1].get('show_order') - b[1].get('show_order'));

        const siteMenu = $('#mega_menu'); // Menü-Container

        sortedData.forEach(([moduleName, module], index) => {
            if (!module.get('show')) return;

            let offsetX = index % 2 === 0 ? -80 + index * 10 : -70 + index * 10;
            let offsetRTL = Math.abs(offsetX);
            let menuType = module.get('type') === 'dropdown' ? 'dropdown' : 'accordion|lg:dropdown';
            let menuItem = $(`
                <div class="menu-item" 
                    data-menu-item-offset="${offsetX}px,10px|lg:${offsetX + 10}px,10px" 
                    data-menu-item-offset-rtl="${offsetRTL}px,10px|lg:${offsetRTL + 10}px,10px"
                    data-menu-item-placement="bottom-start" 
                    data-menu-item-placement-rtl="bottom-end"
                    data-menu-item-toggle="${menuType}"
                    data-menu-item-trigger="click|lg:hover">
                    
                    <div class="menu-link border-b border-b-transparent menu-item-active:border-b-gray-400 menu-item-here:border-b-gray-400">
                        <span class="menu-title text-2sm text-gray-800 dark:menu-item-here:text-gray-900 dark:menu-item-active:text-gray-900
                            menu-item-show:text-gray-900 menu-item-here:text-gray-900 menu-item-active:font-medium menu-item-here:font-medium">
                            ${module.get('name') || module.get('id')}
                        </span>
                        <span class="menu-arrow flex lg:hidden">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex"></i>
                        </span>
                    </div>

                    <div class="menu-dropdown ${module.get('type') === 'dropdown' ? 'menu-default py-2.5 w-full max-w-[220px]' : 'w-full gap-0 lg:max-w-[875px] hidden'}">
                        ${module.get('type') === 'dropdown' ? this.buildDropdownItems(module.get('views')) : this.buildGridMenu(module.get('views'))}
                    </div>
                </div>
            `);

            menuItem.find('.menu-link').on('click', function () {
                menuItem.find('.menu-dropdown').slideToggle(200);
            });

            siteMenu.append(menuItem);
        });
    },
    buildDropdownItems: function (views) {
        if (DEBUG) console.log('navigation.buildDropdownItems()');
        let dropdownHTML = '';

        Object.entries(views).forEach(([viewName, view]) => {
            if (view.get('hidden')) return;
            if (!view.get('topbar')) return;
            let link = view.get('link').replace(/\/\{\w+\?}/g, '');
            dropdownHTML += `
                <div class="menu-item">
                    <a class="menu-link" href="/${link}" tabindex="0">
                        <span class="menu-icon">
                            <i class="${view.get('icon_type') || 'ki-filled'} ${view.get('icon') || 'ki-document'}"></i>
                        </span>
                        <span class="menu-title grow-0">${view.get('name')}</span>
                    </a>
                </div>
            `;
        });

        return dropdownHTML;
    },
    buildGridMenu: function (views) {
        if (DEBUG) console.log('navigation.buildGridMenu()');
        let leftColumn = '', rightColumn = '';
        let index = 0;

        Object.entries(views).forEach(([viewName, view]) => {
            if (view.get('hidden')) return;
            let link = view.get('link').replace(/\/\{\w+\?}/g, '');
            let menuItemHTML = `
                <div class="menu-item">
                    <a class="menu-link" href="/${link}" tabindex="0">
                        <span class="menu-icon">
                            <i class="${view.get('icon_type') || 'ki-filled'} ${view.get('icon') || 'ki-document'}"></i>
                        </span>
                        <span class="menu-title grow-0">${view.get('name')}</span>
                    </a>
                </div>
            `;

            if (index % 2 === 0) {
                leftColumn += menuItemHTML;
            } else {
                rightColumn += menuItemHTML;
            }
            index++;
        });

        return `
            <div class="pt-4 pb-2 lg:p-7.5">
                <div class="grid lg:grid-cols-2 gap-5 lg:gap-10">
                    <div class="menu menu-default menu-fit flex-col">${leftColumn}</div>

                </div>
            </div>
        `;
    }
};

let uiElements = {
    'reloadTitle': function (data = []) {
        // set title
        document.title = configs.get('name') + " | " + configs.get('title');
        if (data.length > 0) {
            document.title = configs.get('name') + " - " + data[0] + " - " + data[1];
        }
    }
}
let user = {
    data: {
        'id': null,
        'firstname': null,
        'lastname': null,
        'name': null,
        'email': null,
        'label': null,
        'initials': null,
    },
    registerUser: function (data) {
        if (DEBUG) console.log('user.register()');
        this.data.id = data.id ?? null;
        this.data.firstname = data.firstname ?? null;
        this.data.lastname = data.lastname ?? null;
        this.data.name = data.name ?? null;
        this.data.email = data.email ?? null;
        this.data.initials = data.initials ?? null;
    },
    get: function () {
        return this.data;
    },
    getUser: function () {
        return this.data;
    },
    output: function () {
        $('#usermenuName').html((this.data.firstname ?? '') + " " + (this.data.lastname ?? ''));
        $('#usermenuLabel').html(this.data.label ?? '');
        $('#usermenuMail').html(this.data.email ?? '');
        $('#usermenuInitials').html(this.data.initials ?? '');
    }
};

setTimeout(() => {
    const themeCheckbox = document.getElementById("thememode");

    if (!themeCheckbox) return; // Falls die Checkbox nicht existiert, abbrechen

    // Setze den Zustand der Checkbox basierend auf dem Theme-Modus
    themeCheckbox.checked = (themeMode === 'dark');

    // Event-Listener für Benutzeränderungen (umschalten)
    themeCheckbox.addEventListener("change", function () {
        if (themeCheckbox.checked) {
            document.documentElement.classList.remove("light");
            document.documentElement.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.add("light");
            localStorage.setItem("theme", "light");
        }
    });

}, 500);

window.user = user;
window.modules = modules;
window.moduleNavigation = moduleNavigation;

export { modules, configs,  siteConfig, user, moduleNavigation, uiElements };