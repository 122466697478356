'use strict';
if (DEBUG) console.log('websocket/client.js');

import ReconnectingWebSocket from "./core.js";
import {configs, moduleNavigation, modules, siteConfig, uiElements} from './app.js';

window.ReconnectingWebSocket = ReconnectingWebSocket;


/**
 * The `sessionToken` variable stores the session token retrieved from the value of an input field with the name "sessionID" in the document.
 * It is typically used to authenticate a user's session in web applications, ensuring that requests are associated with the correct user.
 * The token is extracted using the DOM method querySelector, which targets the input element by its name attribute.
 */
const sessionToken = sessionID;
/**
 * Retrieves the token value from a hidden input field in the document.
 *
 * The _token variable extracts its value from an input element specified
 * by the selector 'input[name="_token"]'. This is commonly used for
 * anti-CSRF (Cross-Site Request Forgery) protection in web applications,
 * where forms include a hidden token that must match the server-side
 * verification to ensure the request comes from a legitimate user session.
 *
 * @type {string}
 */
const _token = document.querySelector('input[name="_token"]').value;

/**
 * Represents a user session with various attributes to track session details and user status.
 *
 * @typedef {Object} asSession
 * @property {string} sessionToken - The unique token identifying the current session.
 * @property {Date|null} startActivity - The timestamp indicating when the session activity started, or null if not set.
 * @property {Date|null} lastActivity - The timestamp of the last recorded activity in this session, or null if not set.
 * @property {Object|null} user - An object representing the user associated with the session, or null if not set.
 * @property {boolean} _loggedIn - A private flag indicating if the user is logged into the session.
 * @property {string|null} _token - A private variable holding an optional initial token or null if not provided.
 */
let asSession = {
    sessionToken: sessionToken,
    startActivity: null,
    lastActivity: null,
    user: null,
    _loggedIn: false,
    _token: (_token) ? _token : null,
    registerSession: function (token) {
        if (DEBUG) console.log("asSession.registerSession()");

    }
}

/**
 * An object representing the current view, providing methods to get, set,
 * initialize, and reset the current route.
 *
 * Properties:
 * - route: Stores the current route as a string or null.
 *
 * Methods:
 * - get: Retrieves the current route.
 * - set: Sets the current route to the provided route value.
 * - new: Initializes the route to the specified value and returns the current route.
 * - reset: Resets the route to null and returns the current route.
 */
let currentView = {
    route: null,
    module: null,
    path: null,
    params: null,
    get: function (key = null) {
        if (key === null) return this.route;
        return this[key];
    },
    set: function (key, val) {
        if (this[key] !== undefined) this[key] = val;

        return this;
    },
    new: function (
        route = null,
        module = null,
        path = null
    ) {
        console.log(route, module, path);
        this
            .set('route', route)
            .set('module', module)
            .set('path', path);
        return this;
    },
    reset: function () {
        this.set(null);
        return this;
    },
}

/**
 * Represents a package with path, server, post, put, and parameters attributes.
 *
 * @property {*|null} path - The path associated with the package.
 * @property {*|null} server - The server configuration for the package.
 * @property {*|null} post - The post request data for the package.
 * @property {*|null} put - The put request data for the package.
 * @property {*|null} parameters - The parameters for the package.
 *
 * @function get
 * @description Returns the current instance of the package object.
 *
 * @function new
 * @description Initializes a new instance of the package object with the specified parameters.
 * @param {string} path - The path for the package.
 * @param {Array} [server=[]] - The server configuration.
 * @param {Array} [post=[]] - The post request data.
 * @param {Array} [put=[]] - The put request data.
 * @param {Array} [parameters=[]] - The parameters for the package.
 * @returns {Object} The initialized package object.
 */
let packageObject = {
    path: null,
    method: 'get',
    parameters: null,
    post: null,
    put: null,
    server: null,
    take: function () {
        return this;
    },
    new: function (path, method = 'get', server = [], post = [], put = [], parameters = []) {
        this.path = path;
        this.method = method;
        this.parameters = parameters;
        this.post = post;
        this.get = put
        this.put = put;
        this.server = server;
        return this.take();
    }
}

/**
 * Represents a request object used for managing and sending requests.
 *
 * @property {string} type - The type of the request, defaults to 'viewRequest'.
 * @property {*} request - An optional property to store request-specific data, initialized to null.
 * @property {*} package - An object or data structure provided by `packageObject.get()`, typically used for request handling.
 * @property {*} current - Represents the current view or state, provided by `currentView.get()`.
 *
 * @method set
 * Sets a specified parameter to a given value within the request object.
 * @param {string} par - The name of the parameter to set.
 * @param {*} val - The value to assign to the parameter.
 * @returns {object} Returns the requestObject for chaining.
 *
 * @method get
 * A function intended to retrieve or compute specific properties or results from the request object.
 * Currently, does not perform any operations.
 *
 * @method send
 * Sends the request to a specified connection with a defined path and optional parameters.
 * @param {object} connection - The connection object to be used for sending the request, typically a network or communication object.
 * @param {string} path - The path or endpoint to which the request will be sent.
 * @param {Array} [parameters=[]] - Optional array of parameters to include in the request package.
 */
let requestObject = {
    type: 'viewRequest',
    request: null,
    package: packageObject.take(),
    current: currentView.get(),
    set: function (par, val) {
        this[par] = val;
        return this;
    },
    get: function () {

    },
    send: function (connection, path, parameters = [], method = 'get') {
        if (DEBUG) console.log("requestObject.send()");
        // if (DEBUG) console.log(connection, path, $('#outputViewController').attr('data-module-url'));
        let content = $('#content');
        console.log(content.data());

        let message = this.package.new(path, method, [], [], parameters);
        this.package.parameters = parameters;

        currentView.new(content.attr('data-url'),
            content.attr('data-module'),
            content.attr('data-path')
        );

        moduleNavigation.buildBackendPageMenu();

        // if (DEBUG) console.log(this.package.parameters);
        let type = this.type;

        connection.send(JSON.stringify({type, message, sessionToken}));
        this.type = 'viewRequest';
    },
}

function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
    return null;
}

/**
 * A configuration object for managing socket connections and related operations.
 *
 * @property {string} socketHost - The host address of the socket server.
 * @property {number} socketPort - The port number on which the socket server is running.
 * @property {string} socketPath - The path used to connect to the socket server.
 * @property {string} socketProtocol - The protocol used for socket communication (e.g., 'ws' or 'wss').
 * @property {boolean} socketPortHidden - Indicates if the socket port should be hidden in the URL.
 * @property {object} socketConnection - An object representing the connection to the socket server.
 *
 * @method get
 * @description Retrieves the value of a specified configuration parameter.
 * @param {string} par - The configuration parameter to retrieve.
 * @returns {*} The value of the specified parameter.
 *
 * @method set
 * @description Sets the value of a specified configuration parameter.
 * @param {string} par - The configuration parameter to set.
 * @param {*} val - The value to assign to the parameter.
 *
 * @method getWebsocketUrl
 * @description Constructs and returns the websocket URL based on the current configuration.
 * @returns {string} The websocket URL.
 *
 * @method register
 * @description Registers a connection with the socket server using a provided function.
 * @param {function} val - A function that returns the socket connection object.
 *
 * @method con
 * @description Returns the current socket connection object.
 * @returns {object} The socket connection object.
 *
 * @method send
 * @description Sends a message of a specific type to the socket server.
 * @param {string} type - The type of message to send.
 * @param {object|string} requestMessage - The message to be sent.
 * @returns {string} Confirmation that the message was sent.
 *
 * @method responseHandler
 * @description Handles incoming messages (events) from the socket server and performs actions based on the message type.
 * @param {object} e - The event object containing the message data from the socket server.
 */

let asSocket = {
    socketHost: socketHost,
    socketPort: socketPort,
    socketPath: socketPath,
    socketProtocol: socketProtocol,
    socketPortHidden: socketPortHidden,
    socketConnection: null,
    lastSendRequest: null,
    lastPing: null,
    lastServerResponse: null,
    get: function (par) {
        return this[par];
    },
    set: function (par, val) {
        this[par] = val;
    },
    getWebsocketUrl: function () {
        if (DEBUG) console.log("asSocket.getWebsocketUrl()");
        return this.get('socketProtocol') + "://" + this.get('socketHost') + ((this.get('socketPortHidden')) ? ("") : (":" + this.get('socketPort'))) + "/" + this.get('socketPath') ;
    },
    register: function (val) {
        if (DEBUG) console.log("asSocket.register()");
        this.socketConnection = val();
    },
    close: function () {
        if (DEBUG) console.log("asSocket.close()");
        this.socketConnection.close(1000, 'Closing connection');
    },
    connection: function () {
        return this.socketConnection;
    },
    send: function (type, requestMessage) {
        let message = requestMessage;
        switch (type) {
            case "connect":
                this.connection().send(JSON.stringify({type, message, sessionToken}));
                break;
            case "viewRequest":
                console.log("asSocket.send() viewRequest");
                this.lastSendRequest = new Date();
                if (DEBUG) $('#requestInfo').html("lastRequest" + this.lastSendRequest.toLocaleString());
                requestObject.send(this.connection(), message);
                break;
            case "pong":
                console.log("asSocket.send() pong");
                this.lastPing = new Date();
                if (DEBUG) $('#pingInfo').html("lastPing: " + this.lastPing.toLocaleString());
                this.connection().send(JSON.stringify({type, message, sessionToken}));
                break;
            default:
                this.connection().send(JSON.stringify({type, message, sessionToken}));
                break;
        }
        return "message sent";
    },
    responseHandler: function (e) {
        if (DEBUG) console.log("asSocket.responseHandler()");
        let response = JSON.parse(e.data);
        let responseType = response.type;
        let responseMessage = response.message;
        let sessionID = response.sessionToken;
        let __cookie = response.__cookie;
        let state = response.state ?? 200;
        let outputViewController = window.document.getElementById("outputViewController");
        let viewControllerMenu = window.document.getElementById("viewControllerMenu");
        let objectID = null;
        let fieldIndex;
        const timestampInSeconds = Math.floor(Date.now() / 1000);

        asSession.lastActivity = new Date();

        if (sessionID) {
            const currentSession = decodeURIComponent(getCookie('app_session'));
            if (currentSession !== sessionID) {
                document.cookie = "app_session=" + __cookie.app_session;
            }
        }

        if (state !== 200) {
            if(DEBUG)  console.log("responseHandler " + responseMessage);
        }

        switch (responseType) {
            case "auth":
                if (DEBUG) console.log("responseHandler " + responseType);
                asSession._loggedIn = responseMessage;

                break;

            case "connected":
                if(DEBUG) console.log("responseHandler " + responseType);
                asSession.startActivity = new Date();
                asSocket.send('config', 'need configuration');
                break;

            case "ping":
                if(DEBUG) console.log("responseHandler " + responseType);
                asSocket.send('pong', timestampInSeconds);
                break;

            case "viewResponse":
                asSocket.viewResponseHandler(responseMessage);
                break;

            case "userInformations":
            case "user":
                if (DEBUG) console.log("responseHandler for user");
                let displayUser = JSON.parse(e.data).message;
                asSession.user = displayUser.id;
                user.registerUser(displayUser);
                user.output();
                break;
            case "config":
                if (DEBUG) console.log("responseHandler for configs");
                modules.registerModules(responseMessage.modules);
                // console.log(modules.getModules());
                // if (DEBUG) console.log(responseMessage.app);
                siteConfig.app = responseMessage.app;
                siteConfig.modules = responseMessage.modules;
                if (responseMessage.app !== undefined) configs.registerConfig(responseMessage.app);
                uiElements.reloadTitle();
                moduleNavigation.build();
                break;

            case "outputViewController_deprecated":
                if (DEBUG) console.log("responseHandler for outputViewController");

                $(outputViewController).empty();
                $(outputViewController).attr('data-module-url', responseMessage.current);
                siteConfig.setCurrentModule(responseMessage.currentModuleView, responseMessage.current);
                // console.log(siteConfig.getCurrentModule());
                KTAppSidebar.reload();
                localStorage.setItem('appUrl', responseMessage.current);

                let toolbarhome = window.document.getElementById("toolbar_home");
                let toolbarmodule = window.document.getElementById("toolbar_module");
                let toolbarparent = window.document.getElementById("toolbar_parent");
                let toolbarcurrent = window.document.getElementById("toolbar_currentview");
                if (responseMessage.currentModuleView !== null) {
                    if (DEBUG) console.log("current moduleview: " + responseMessage.currentModuleView.module.config.name);
                    toolbarcurrent.innerHTML = responseMessage.currentModuleView.view.config.title;
                    toolbarmodule.innerHTML = responseMessage.currentModuleView.module.config.title;
                    // if (DEBUG) console.log("responseHandler " + responseType, JSON.parse(e.data), responseMessage.output);
                    uiElements.reloadTitle([responseMessage.currentModuleView.module.config.name, responseMessage.currentModuleView.view.config.title]);
                }

                let sidebar = $('#\\#kt_app_sidebar_menu');
                if (typeof responseMessage.output === 'object') {
                    if (responseMessage.output.code === 0) {
                        outputViewController.innerHTML = responseMessage.output.msg + " in <br> " + responseMessage.output.file + ":" + responseMessage.output.line + " <br>code " + responseMessage.output.code + "<br>trace " + responseMessage.output.trace;
                    }
                }

                let currentRoute = responseMessage.currentModuleView.view.config.route ?? null;
                let parentUrl = responseMessage.currentModuleView.view.config.parent ?? null;

                if (parentUrl !== null) parentUrl = parentUrl.replace(".", "/");
                else parentUrl = currentRoute;

                let parentView = parentUrl.includes("/") ? parentUrl.split("/")[1] : null;

                if (parentView !== null && siteConfig.getCurrentModule().module.views[parentView] !== undefined) {
                    $('#breadcrumb_item_parent_divider').show();
                    let a = document.createElement("a");
                    $(a).attr('href', siteConfig.getCurrentModule().module.views[parentView].config.route).html(siteConfig.getCurrentModule().module.views[parentView].config.title);
                    $(toolbarparent).html(a);
                } else {
                    $('#breadcrumb_item_parent_divider').hide();
                    $(toolbarparent).html('');
                }

                if (DEBUG) console.log("parentUrl", parentUrl);

                // Entfernen der `active` Klasse von allen Menüelementen innerhalb der Sidebar
                sidebar.find('.menu-link').removeClass('active');
                sidebar.find('.menu-accordion').removeClass('show').removeClass('hover').removeClass('here')
                sidebar.find('.menu-sub-accordion').hide();

                // replace {?}
                currentRoute = currentRoute.replace(/\/\{\w+\?*}/g, '');
                // Hinzufügen der `active` Klasse zum Menüelement, das der aktuellen Route entspricht, innerhalb der Sidebar
                let activeElement = sidebar.find('a[href="' + parentUrl + '"]').addClass('active');

                if (activeElement.length === 0) {
                    activeElement = sidebar.find('a[href="' + parentUrl + '"]').addClass('active');
                }

                activeElement.closest('.menu-accordion').addClass('show').addClass('hover').addClass('here');
                activeElement.closest('.menu-sub-accordion').show();

                outputViewController.innerHTML = responseMessage.output;
                if (viewControllerMenu !== null) viewControllerMenu.innerHTML = "Dieses Menü ändert sich je nach View " + responseMessage.menu;
                break;
            case "redirect":
                console.log("Es wurde ein redirect ausgelöst. Dies muss noch implementiert werden.");
                break;
            case "error":
            case "errormessage":
                console.log("responseHandler for error");
                console.log(responseMessage);
                alert(responseMessage);
                break;
            case "notification":
                showToast(responseMessage.message, "Rückmeldung", {'type': responseMessage.type})
                break;
            case "connectionRefused":
                if (DEBUG) console.log("responseHandler for connection refused");
                window.location.href = "/logout";
                break;
            case "dataObject":
                if (DEBUG) console.log("responseHandler " + responseType, JSON.parse(e.data));

                if (JSON.parse(e.data).message.msg !== undefined) {
                    if (DEBUG) console.log(JSON.parse(e.data).message.msg);
                    if (DEBUG) console.log(JSON.parse(e.data).message.trace);
                }
                let r = responseMessage;
                let dataSetID = '';
                objectID = r.objectProperties.objectID;
                let objectType = r.objectProperties.objectType;

                if (objectType === 'form') {
                    let formularTitle = $('#formObjectTitle[data-object-id=' + objectID + ']');
                    if ($('form#' + objectID).data('id') !== '') {
                        dataSetID = $('form#' + objectID).data('id');
                    }

                    setDataObject(r);
                    getDataObject(objectID).formular = $('form#' + objectID);
                    getDataObject(objectID).fetchDataSet(dataSetID)
                    formularTitle.text(dOC.get(objectID).getObjectProperty('objectName'));
                } else if (objectType === 'list') {

                    let element = $('#' + objectID);
                    setDataObject(r);
                    getDataObject(objectID).outputTable(element);
                }

                break;
            case "dataSet":
                objectID = responseMessage.objectID;
                if (DEBUG) console.log("responseHandler " + objectID + " " + responseType, responseMessage);
                setTimeout(function () {
                    if (responseMessage.state === 'error') {
                        alert(responseMessage.error);
                    }
                });
                if (responseMessage.state === 'error') {
                    alert(responseMessage.error);
                }
                fieldIndex = getDataObject(objectID).getFieldIndex();
                getDataObject(objectID).results = responseMessage.fieldValues;
                let elVal;

                for (let el in fieldIndex) {
                    if (responseMessage.fieldValues !== null) elVal = responseMessage.fieldValues[el];
                    else elVal = null;
                    const fieldValue = getDataObject(objectID)
                        .setFieldValue(el, elVal)
                        .getFieldValue(el);

                    $('#' + objectID + '_' + el)
                        .val(fieldValue);

                    const formViewDiv = $(`div[id=form_view_${objectID}_${el}]`);

                    if (!fieldValue) {
                        formViewDiv.html('nicht gesetzt??');
                    } else {
                        formViewDiv.html(fieldValue);
                    }
                }

                if (getDataObject(objectID).getFormProperty('viewMode') === 'view') getDataObject(objectID).renderViewEntryFormBody();
                else getDataObject(objectID).renderEditEntryFormBody();

                break;
            case "getResults":
                if (DEBUG) console.log("responseHandler " + responseType);
                getDataObject(responseMessage.objectID).setResults(JSON.parse(e.data).message);
                break;
            case "saveEntry":
                if (DEBUG) console.log("responseHandler " + responseType);
                if (typeof responseMessage === 'string') {
                    break;
                }

                objectID = responseMessage.objectID;

                fieldIndex = getDataObject(objectID).getFieldIndex();
                if (DEBUG) console.log("responseHandler " + responseType, responseMessage);
                getDataObject(objectID).results = responseMessage.fieldValues;
                let elVal2;
                for (let el in fieldIndex) {
                    if (responseMessage.fieldValues !== null && responseMessage.fieldValues !== undefined) elVal2 = responseMessage.fieldValues[el];
                    else elVal2 = null;
                    const fieldValue = getDataObject(objectID)
                        .setFieldValue(el, elVal2)
                        .getFieldValue(el);

                    $('#' + objectID + '_' + el)
                        .val(fieldValue);

                    const formViewDiv = $(`div[id=form_view_${objectID}_${el}]`);

                    if (!fieldValue) {
                        formViewDiv.html('nicht gesetzt??');
                    } else {
                        formViewDiv.html(fieldValue);
                    }
                }

                if (DEBUG) console.log("responseHandler " + responseType, JSON.parse(e.data));
                if (getDataObject(objectID).getFormProperty('viewMode') === 'view') getDataObject(objectID).renderViewEntryFormBody();
                else getDataObject(objectID).renderEditEntryFormBody();

                $('#tab-dom-view-' + objectID).click();
                showToast('Änderungen wurden gespeichert', "Änderungen Erfolgreich", {'type': 'success'})
                break;
            case "changeValue":
                if (DEBUG) console.log("responseHandler " + responseType, responseMessage);
                try {
                    var dataTable = getDataObject(responseMessage.objectID).dataTable;
                    dataTable.draw();
                } catch (error) {
                    console.error('Error during DataTable draw:', error);
                }

                $('#' + responseMessage.objectID + '_processing').hide();
                if (responseMessage.state === true) showToast('Änderungen wurden gespeichert', "Änderungen Erfolgreich", {'type': 'success'})
                break;
            default:

                break;
        }
    },
    connectionHandler: function(responseMessage) {},
    viewResponseHandler: function(responseMessage) {
        if (DEBUG) console.log("viewResponseHandler status:" + responseMessage.status);
        let content = $('#content');
        content.empty();

        if (responseMessage.status === 200) {
            if (responseMessage.data !== undefined) {
                content.html(responseMessage.data);
            }
        } else if (responseMessage.status === 302) {
            if (responseMessage.data !== undefined) {
                content.html(responseMessage.data);
            }
        } else this.errorHandler(responseMessage);
    },
    errorHandler: function(responseMessage) {
        let content = $('#content');
        content.empty();
        if (DEBUG) console.log("asSocket.errorHandler ", responseMessage);
        if (responseMessage.status >= 400) {
            let imgSrc = "assets/media/illustrations/19-dark.svg";
            let errorBadge = responseMessage.status + " Error";
            let errorTitle = "We have lost this page";
            let errorMessage = `The requested page is missing. Check the URL or&nbsp;
            <a href="/" class="text-primary font-medium hover:text-primary-active">Return Home</a>.`;

            if (responseMessage.status === 500) {
                imgSrc = "assets/media/illustrations/20-dark.svg";
                errorBadge = "500 Error";
                errorTitle = "Internal Server Error";
                errorMessage = `An internal server error occurred. Please try again later or&nbsp;
                <a href="/" class="text-primary font-medium hover:text-primary-active">Return Home</a>.`;
            }

            let serverErrorMessage = "";
            if (responseMessage.data && responseMessage.data.message) {
                serverErrorMessage = `<div class="alert alert-danger mt-3">${responseMessage.data.message}</div>`;
            }

            const html = `
          <div class="mb-10 text-center">
            <img
              src="${imgSrc}"
              class="max-h-[160px] mx-auto"
              alt="image"
            />
          </div>

          <div class="text-center">
            <span class="badge badge-primary badge-outline mb-3">${errorBadge}</span>
          </div>

          <h3 class="text-2.5xl font-semibold text-gray-900 text-center mb-2">
            ${errorTitle}
          </h3>

          <div class="text-md text-center text-gray-700 mb-10">
                ${serverErrorMessage}            
                ${errorMessage}
          </div>
        `;

            content.html(html);
        }
    }
}

asSocket.register(function () {
    return new window.ReconnectingWebSocket(asSocket.getWebsocketUrl());
});

asSocket.connection().onopen = function (e) {
    if (DEBUG) console.log("asSocket.connection().onopen()");
    asSocket.send('connect', 'wanna connect');
};

asSocket.connection().onclose = function (e) {
    if (DEBUG) console.log("asSocket.connection().onclose()");
    asSocket.send('disconnect', 'bye');
    asSocket.close();
};

asSocket.connection().onmessage = function (e) {
    if (DEBUG) console.log("asSocket.connection().onmessage()");
    asSocket.responseHandler(e);
};

window.asSocket = asSocket;
window.asSession = asSession;
window.currentView = currentView;
window.packageObject = packageObject;
window.requestObject = requestObject;

export { asSession, currentView, packageObject, requestObject, asSocket };